<template>
  <div id="moveTo" v-show="gameStarted && !gameEnded">
    <div class="clickHere" :class="{showing:clickHere}"><object data="/images/wegovy_click-to-roll.svg" type="image/svg+xml"></object></div>
    <div :class="['diceWrapper', gameTypeClass, { disabled: isAnimating||waiting||store.getters.getCardsShowing }]" @click="rollDice">
      <section class="diceWrapper1">
        <div ref="dice1" id="dice1">
          <dice-individual-component></dice-individual-component>
        </div>
      </section>
      <section v-if="gameType !== 12 && gameType !== 'Random 1'" class="diceWrapper2">
        <div ref="dice2" id="dice2">
          <dice-individual-component></dice-individual-component>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted,watch } from 'vue';
import tracksData from '@/assets/tracks.json';
import variables from '@/assets/variables.json';
import { useStore } from 'vuex';
import DiceIndividualComponent from '@/components/game/DiceComponents/DiceIndividualComponent.vue';

export default {
  components: {
    DiceIndividualComponent
  },
  props: {
    totalSpaces: Number,
    soundUtility: Object,
    clickHere: Boolean
  },
  emits: ['roll', 'diceRolled', 'startSoundIfNotStarted'],
  setup(props, { emit }) {
    const store = useStore();
    const waiting = ref(true);
    const roll = ref(0);
    const dice1 = ref(null);
    const dice2 = ref(null);
    const myTrack = ref(null);
    const gameStarted = computed(() => store.getters.getGameStarted);
    const gameEnded = computed(() => store.getters.getGameEnded);
    const isAnimating = computed(() => store.getters.getIsAnimating);
    const gameType = computed(() => store.getters.getGameType);
    const currentSpace = computed(() => store.getters.getCurrentSpace);

    //if gameType is 12 or gameType is 'Random 1', then only one dice is shown
    const gameTypeClass = computed(() => gameType.value === 12 || gameType.value === 'Random 1' ? 'oneDice' : 'twoDice');
    const newSpace = computed(() => {
      if (!myTrack.value || roll.value >= myTrack.value.length) return null;
      let space = currentSpace.value + myTrack.value[roll.value].dice1;
      space += (gameType.value === 4 || gameType.value === 8) ? myTrack.value[roll.value].dice2 : 0;
      return Math.min(space, props.totalSpaces - 1);
    });

    watch(
        () => store.getters.getGameStarted,
        () => {
          if (store.getters.getGameStarted) {
            setTimeout(() => {
              waiting.value = false;
            }, 2000);
          }
        }
    );


    onMounted(() => {
      if (props.totalSpaces) {
        initializeTrack();
        if (store.getters.getRoll) roll.value = store.getters.getRoll;
      }
    });

    const initializeTrack = () => {
      if(store.getters.getGameStarted){
        waiting.value = false;
      }
      if (gameType.value === 'Random 1' || gameType.value === 'Random 2') {
        myTrack.value = gameType.value;
      } else {
        if (!store.getters.getMyTrack) {
          if (store.getters.getFixedTrack) {
            setFixedTrack();
          } else {
            setRandomTrack();
          }
          store.dispatch('setMyTrack', myTrack.value);
        } else {
          myTrack.value = store.getters.getMyTrack;
        }
      }

    };

    const setFixedTrack = () => {
      switch (gameType.value) {
        case 4: myTrack.value = tracksData.track4[variables.fixed4]; break;
        case 8: myTrack.value = tracksData.track8[variables.fixed8]; break;
        case 12: myTrack.value = tracksData.track12[variables.fixed12]; break;
        default: myTrack.value = tracksData.track4[variables.fixed4]; // Default track
      }
    };

    const setRandomTrack = () => {
      switch (gameType.value) {
        case 4: myTrack.value = tracksData.track4[Math.floor(Math.random() * tracksData.track4.length)]; break;
        case 8: myTrack.value = tracksData.track8[Math.floor(Math.random() * tracksData.track8.length)]; break;
        case 12: myTrack.value = tracksData.track12[Math.floor(Math.random() * tracksData.track12.length)]; break;
        default: myTrack.value = tracksData.track4[0]; // Default track
      }
    };

    const rollDice = () => {
      emit('startSoundIfNotStarted');
      if (!isAnimating.value && !waiting.value && !store.getters.getCardsShowing) {
        playDiceRollSound();
        emit('roll');
        if (currentSpace.value < props.totalSpaces) {
          if (gameType.value === 'Random 1' || gameType.value === 'Random 2') {
            randomDiceRoll();
          } else {
            fixedDiceRoll();
          }
          roll.value++;
          store.dispatch('setRoll', roll.value);
        }
      }
    };

    const playDiceRollSound = () => {
      props.soundUtility.playSound('diceRoll', 0.02, false);
    };

    const randomDiceRoll = () => {
      const dice1Roll = Math.floor(Math.random() * 6) + 1;
      applyDiceRotation(dice1, dice1Roll, roll.value);
      let space = currentSpace.value + dice1Roll;
      if(gameType.value === 'Random 2') {
        const dice2Roll = Math.floor(Math.random() * 6) + 1;
        applyDiceRotation(dice2, dice2Roll, roll.value);
        space += dice2Roll;
      }
      emit('diceRolled', Math.min(space, props.totalSpaces - 1));
    };

    const fixedDiceRoll = () => {
      applyDiceRotation(dice1, myTrack.value[roll.value].dice1, roll.value);
      if (gameType.value === 4 || gameType.value === 8) {
        applyDiceRotation(dice2, myTrack.value[roll.value].dice2, roll.value);
      }
      emit('diceRolled', newSpace.value);
    };

    const applyDiceRotation = (diceRef, diceNumber, rollCount) => {
      if (diceRef.value) {
        const rotations = getRotationForNumber(diceNumber);
        const finalXRotation = rotations.x + 360 * 3 * (rollCount + 1);
        const finalYRotation = rotations.y + 360 * 3 * (rollCount + 1);
        diceRef.value.style.transition = 'transform 1s';
        diceRef.value.style.transform = `rotateX(${finalXRotation}deg) rotateY(${finalYRotation}deg)`;
      }
    };

    const getRotationForNumber = (number) => {
      switch (number) {
        case 1: return { x: 0, y: 0 };
        case 2: return { x: 180, y: 0 };
        case 3: return { x: 0, y: -90 };
        case 4: return { x: 0, y: 90 };
        case 5: return { x: -90, y: 0 };
        case 6: return { x: 90, y: 0 };
        default: return { x: 0, y: 0 }; // Default to 1 if an invalid number is given
      }
    };

    return {
      gameStarted,
      gameEnded,
      isAnimating,
      gameType,
      gameTypeClass,
      currentSpace,
      newSpace,
      rollDice,
      roll,
      dice1,
      dice2,
      store,
      waiting
    };
  },
};
</script>

<style lang="scss">
:root {--full-size: min(20vw, 150px);--container-size: calc(var(--full-size) / 4);}
#moveTo {position: fixed;
  .clickHere{position: absolute;left:64%;transform: translate(-50%,-30%);color:#fff;opacity:0;transition: opacity 0.5s;width:125%;text-align:center;
    &.showing {opacity: 1;animation: bounce 2s infinite;}
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translate(-50%, -35%);
    }
    40% {
      transform: translate(-50%, -45%);
    }
    60% {
      transform: translate(-50%, -40%);
    }
  }
  // bounce animation up and down on clickHere

  .diceWrapper {position: relative;width: var(--full-size);height: var(--full-size);border: 1px solid #ccc;border-radius: 50%;cursor: pointer;background: rgba(255, 255, 255, 0.5);
    &::after {content: "";display: block;width: 100%;height: 100%;border-radius: 50%;background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4) 30%, transparent 60%);box-shadow: 0 0 min(1vw, 15px) rgba(0, 0, 0, 0.3);position: absolute;z-index: 5;}
    &:hover {&::after {background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.5) 30%, transparent 60%);box-shadow: 0 0 min(1vw, 15px) rgba(0, 0, 0, 0.4);}}
    .diceWrapper1, .diceWrapper2 {width: var(--container-size);height: var(--container-size);position: absolute;perspective: 1000px;perspective-origin: 50% 100%;top: 50%;transform: translate(-50%, -50%);}
    &.oneDice {.diceWrapper1 {left: 50%;}}
    &.twoDice {.diceWrapper1 {left: 33%;} .diceWrapper2 {left: 67%;}}
    #dice1, #dice2 {width: 100%;height: 100%;position: absolute;transform-style: preserve-3d;transition: transform 6s;
      div {background: #fff;box-shadow: 0 0 min(0.5vw, 2px) #000;display: block;position: absolute;width: 100%;height: 50%;margin: 0 auto;padding: 25% 0;}
      &:hover, &:focus {cursor: none;}
      #dice1 .front, #dice2 .front {transform: translateZ(calc(var(--container-size) / 2));}
      .back {transform: rotateX(-180deg) translateZ(calc(var(--container-size) / 2));}
      .right {transform: rotateY(90deg) translateZ(calc(var(--container-size) / 2));}
      .left {transform: rotateY(-90deg) translateZ(calc(var(--container-size) / 2));}
      .top {transform: rotateX(90deg) translateZ(calc(var(--container-size) / 2));}
      .bottom {transform: rotateX(-90deg) translateZ(calc(var(--container-size) / 2));}
      .dot {display: block;position: absolute;width: 15%;height: 15%;background: #000;border-radius: 50%;}
      .front {.dot1 {top: 42.5%; left: 42.5%;}}
      .back {.dot1 {top: 22.5%; left: 22.5%;} .dot2 {top: 62.5%; left: 62.5%;}}
      .right {.dot1 {top: 22.5%; left: 22.5%;} .dot2 {top: 42.5%; left: 42.5%;} .dot3 {top: 62.5%; left: 62.5%;}}
      .left {.dot1 {top: 22.5%; left: 22.5%;} .dot2 {top: 22.5%; left: 62.5%;} .dot3 {top: 62.5%; left: 22.5%;} .dot4 {top: 62.5%; left: 62.5%;}}
      .top {.dot1 {top: 22.5%; left: 22.5%;} .dot2 {top: 22.5%; left: 62.5%;} .dot3 {top: 42.5%; left: 42.5%;} .dot4 {top: 62.5%; left: 22.5%;} .dot5 {top: 62.5%; left: 62.5%;}}
      .bottom {.dot1 {top: 22.5%; left: 22.5%;} .dot2 {top: 22.5%; left: 42.5%;} .dot3 {top: 22.5%; left: 62.5%;} .dot4 {top: 62.5%; left: 22.5%;} .dot5 {top: 62.5%; left: 42.5%;} .dot6 {top: 62.5%; left: 62.5%;}}
    }
    &.disabled {opacity: 0.5;cursor: not-allowed;
      &:hover {&::after {content: "";display: block;width: 100%;height: 100%;border-radius: 50%;background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4) 30%, transparent 60%);box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);position: absolute;z-index: 5;}}
    }
  }
}
@media screen and (max-aspect-ratio: 1/1) {:root {--full-size: min(20vw, 150px);--container-size: calc(var(--full-size) / 4);} #moveTo {left: min(2vw, 15px);bottom: min(18vw, 135px);.clickHere {font-size: min(3vw, 18px);}}}
@media screen and (min-aspect-ratio: 1/1) {:root {--full-size: min(20vh, 150px);--container-size: calc(var(--full-size) / 4);} #moveTo {left: min(2vh, 15px);bottom: min(18vh, 135px);.clickHere {font-size: min(3vh, 18px);}}}
</style>

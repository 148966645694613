import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export function useValidation() {
    const code = ref('');
    const error = ref(false);
    const errorMessage = ref('Invalid code or hash. Please try again.');
    const hash = ref(null);
    const endPoint = 'https://admin.obesityinteractive.com/api/start';
    const endPoint2 = 'https://admin.obesityinteractive.com/api/validate-cache';
    const jwt = ref(null);
    const game = ref([]);
    const store = useStore();

    function getHashFromURL() {
        const url = new URL(window.location.href);
        return url.pathname.split('/')[1];
    }

    async function validateCode() {
        if (code.value.length === 8) {
            try {
                const response = await fetch(`${endPoint}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ code: code.value })
                });
                if (response.ok) {
                    const result = await response.json();
                    if (result.jwt) {
                        error.value = false;
                        errorMessage.value = '';
                        store.dispatch('saveUserData', {
                            jwt: result.jwt,
                            game: result.game,
                            token: result.token
                        });
                        return result.token; // Only return true if the result is valid
                    } else {
                        error.value = true;
                        errorMessage.value = result.error.message;
                        return false; // Return false if the response is not valid
                    }
                } else {
                    const result = await response.json();
                    error.value = true;
                    errorMessage.value = result.error.message;
                    return false; // Return false if the response is not ok
                }
            } catch (err) {
                error.value = true;
                errorMessage.value = err.message;
                return false; // Return false on fetch error
            }
        } else {
            error.value = true;
            errorMessage.value = 'Invalid code. Please try again.';
            return false; // Return false for invalid code length
        }
    }

    async function validateStorage(jwtToken,lastModifiedGame,lastModifiedQuestionSet) {
        try {
            const response = await fetch(`${endPoint2}?lastModifiedGame=${lastModifiedGame}&lastModifiedQuestionSet=${lastModifiedQuestionSet}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                return result.isValid;
            } else {
                const result = await response.json();
                error.value = true;
                errorMessage.value = result.error.message;
                return false; // Return false if the response is not ok
            }
        } catch (err) {
            error.value = true;
            errorMessage.value = err.message;
            return false; // Return false on fetch error
        }
    }

    async function validateHash() {
        if (hash.value) {
            try {
                const response = await fetch(`${endPoint}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: hash.value })
                });
                if (response.ok) {
                    error.value = false;
                    const result = await response.json();
                    jwt.value = result.jwt;
                    game.value = result.game;
                    store.dispatch('saveUserData', {
                        jwt: result.jwt,
                        game: result.game,
                        token: hash.value
                    });
                    return true;
                } else {
                    const result = await response.json();
                    error.value = true;
                    errorMessage.value = result.error.message;
                    return false;
                }
            } catch (err) {
                error.value = true;
                errorMessage.value = err.message;
                return false;
            }
        }
        return false;
    }

    onMounted(() => {
        hash.value = getHashFromURL();
    });

    return { code, error, errorMessage, validateCode, hash, validateHash, jwt, game, validateStorage };
}

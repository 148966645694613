<template>
  <div :class="[{ disabled: !store.getters.getGameEnded }]" id="endGame">
    <confetti-component :gameEnded="store.getters.getGameEnded"></confetti-component>
    <stats-component v-if="store.getters.getGameEnded"></stats-component>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import ConfettiComponent from '@/components/game/EndGameComponents/ConfettiComponent.vue';
import StatsComponent from '@/components/game/EndGameComponents/StatsComponent.vue';
export default {
  emits: ['restartGame'],
  components: {
    ConfettiComponent,
    StatsComponent
  },
  setup() {
    const store = useStore();
    return {  store };
  }
}
</script>
<style scoped lang="scss">
#endGame {position: absolute;top: 0;left: 0;width: 100%;height: 100%;height: 100dvh;opacity: 1;transition: opacity 2s ease-in-out;
  @media screen and (max-aspect-ratio: 1/1) {
    svg {margin:auto;display:block;position:absolute;width:auto;height:100%;top:0;left:0;}
  }
  @media screen and (min-aspect-ratio: 1/1) {
    svg {margin:auto;display:block;position:absolute;width:100%;height:auto;top:0;left:0;}
  }
  @keyframes ld-fall {
    0%    { transform: translate(0,0px) rotate3d(1,1,1,0deg); }
    16.6% { transform: translate(0,177.5px) rotate3d(1,1,1,180deg); }
    33.3% { transform: translate(0,355px) rotate3d(1,1,1,360deg); }
    50.0% { transform: translate(0,532.5px) rotate3d(1,1,1,540deg); }
    66.6% { transform: translate(0,710px) rotate3d(1,1,1,720deg); }
    83.3% { transform: translate(0,887.5px) rotate3d(1,1,1,900deg); }
    100%  { transform: translate(0,1065px) rotate3d(1,1,1,1080deg) ; }
  }
  .ld.ld-fall {
    animation: ld-fall 4s linear infinite;
  }
  .ld.ld-fall2 {
    animation: ld-fall 4s linear infinite;
    animation-fill-mode: forwards;
  }
  &.disabled {opacity:0;z-index: -3;}
  #intro{background:rgba(0,0,0,0.5);border:1px solid #fff;padding:20px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);text-align: center;width: 80%;max-width: 600px;
    h1 {font-size: min(5vw, 40px);color: #fff;}
    p {color: #fff;font-size: min(3.5vw, 20px);}
    button {padding: 10px 20px;background: #fff;border: none;border-radius: 5px;cursor: pointer;font-size: min(3.5vw, 20px);}
  }
}
</style>

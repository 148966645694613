<template>
  <div id="debug">
    <button class="toggle" @click="toggleVisibility">
      <font-awesome-icon :icon="visible ? 'close' : 'caret-up'" />
    </button>
    <div class="data" v-if="visible">
      <h4>Performance Data:</h4>
      <div v-if="performanceData">
        <p>Overall Performance: {{ performanceData.combinedRating }}</p>
        <p>Game: {{game.Name}}</p>
        <p>Game Type: {{game.QuestionSet.GameType}}</p>
        <p>Fixed Track: {{game.FixedTrack}}</p>
        <p>Roll: {{roll}}</p>
        <p>Current Space: {{currentSpace}}</p>
        <p>Current Space Type: {{currentSpaceType}}</p>
        <p>True/False: {{trueFalse}}</p>
        <p>Multiple Choice: {{multipleChoice}}</p>
        <p>Fill in the Blank: {{fillInBlank}}</p>
        <p>Unscramble: {{unscramble}}</p>
      </div>
      <button class="clear" @click="clearGameData">Clear Game Data</button>
    </div>
  </div>
</template>

<script>
import { mapState, useStore } from 'vuex';
import { ref } from 'vue';
export default {
  name: 'DebugBox',
  computed: {
    ...mapState(['performanceData']),
    ...mapState(['game']),
    ...mapState(['roll']),
    ...mapState(['currentSpace']),
    ...mapState(['currentSpaceType']),
    ...mapState(['trueFalse']),
    ...mapState(['multipleChoice']),
    ...mapState(['fillInBlank']),
    ...mapState(['unscramble']),
  },
  setup() {
    const visible = ref(false);
    // if localhost, set debug to true

    const store = useStore();

    const clearGameData = () => {
      store.dispatch('resetGameData');

      // Optionally refresh or navigate to a starting route
      window.location.reload(); // Uncomment this line to refresh the page
    };
    const toggleVisibility = () => {
      visible.value = !visible.value;
    };

    return { visible, toggleVisibility, clearGameData };
  }
}
</script>
<style lang="scss" scoped>
  #debug {position: fixed; bottom: 0; right: 0; padding: 0; background-color: rgba(255, 255, 255, 0.8); color: black; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);z-index: 9999;
    .toggle {position:absolute;right:0;bottom:0;cursor:pointer;display: block; border-radius:0; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); width:30px; height:30px; line-height:30px; border:0; background-color: transparent; color: black; padding: 0;}
    .data{padding:10px;}
    .clear{cursor:pointer;margin-bottom: 10px;padding: 10px 20px;background-color: darkkhaki;color: black;border: none;border-radius: 5px;cursor: pointer;
      &:hover{background-color: darkgoldenrod;}
    }
  }
</style>
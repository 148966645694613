<template>
  <div v-show="!loading" id="gameBoard" ref="gameBoard"></div>
  <div v-show="loading" class="loading">
    <div class="loading-wrapper">
      <div class="text">Loading...</div>
      <div class="loading__progress">
        <div class="loading__progress-bar" :style="{ width: percentLoaded + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, watch, computed } from 'vue';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import variables from '@/assets/variables.json';
import { useStore } from 'vuex';
export default {
  props: {
    placesData: Object,
    soundUtility: Object,
  },
  emits: ['rollDone', 'endGame', 'loaded'],
  setup(props,{ emit }) {

    const store = useStore();
    // Refs
    const gameBoard = ref(null);
    const loading = ref(true);
    const percentLoaded = ref(0);
    // Variables
    let mixer = null;
    let action1, action2, action3;
    const clock = new THREE.Clock();
    let previousTime = 0;
    let scene, camera, renderer;

    const shadowsEnabled = computed(() => store.getters.getPerformanceData.combinedRating > 2);
    //if store.getters.getPerformanceData.combinedRating is less than 4 then set variables.idleOffsetAbove to variables.movingOffsetAbove
    if(store.getters.getPerformanceData.combinedRating < 2){
      variables.movingOffsetAbove = variables.idleOffsetAbove;
    }
    if(store.getters.getPerformanceData.combinedRating < 2){
      variables.movingOffsetBehind = variables.idleOffsetBehind;
    }
    const pixelRatio = computed(() => {
      switch (store.getters.getPerformanceData.combinedRating) {
        case 4:
          return window.devicePixelRatio;
        case 3:
          return window.devicePixelRatio * 0.75;
        case 2:
          return window.devicePixelRatio * 0.5;
        case 1:
        default:
          return 1;
      }
    });
    const antialias = computed(() => store.getters.getPerformanceData.combinedRating >= 2);
    const shadowSize = computed(() => {
      switch (store.getters.getPerformanceData.combinedRating) {
        case 4:
          return 8192;
        case 3:
          return 4096;
        case 2:
          return 2048;
        case 1:
        default:
          return 1024;
      }
    });
    const manager = new THREE.LoadingManager();
    const gamePiece = new THREE.Group();
    const gameBoardGroup = new THREE.Group();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');
    const gltfLoader = new GLTFLoader(manager);
    gltfLoader.setDRACOLoader(dracoLoader);

    manager.onLoad = () => {
      loading.value = false;
      emit('loaded');
    };

    manager.onProgress = (url, itemsLoaded, itemsTotal) => {
      percentLoaded.value = (itemsLoaded / itemsTotal) * 100;
    };

    manager.onError = (url) => {
      console.error(`There was an error loading ${url}`);
    };

    const loadEnvironmentMap = () => {
      scene.background = new THREE.Color(Number(variables.mainColor));
      if (variables.fogEnabled) {
        scene.fog =
            variables.fogType === 'exponential'
                ? new THREE.FogExp2(Number(variables.fogColor), variables.fogDensity)
                : new THREE.Fog(Number(variables.fogColor), variables.fogNear, variables.fogFar);
      }
    };

    const loadCamera = () => {
      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 10, 1000);
      if (store.getters.getCurrentSpace) {
        const place = props.placesData[store.getters.getCurrentSpace];
        const initialCameraPosition = calculateCameraPosition(place, false); // Not animating at load
        camera.position.set(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z);
        camera.lookAt(place.x, place.y, place.z);
      } else {
        camera.position.set(variables.startCameraPositionX, variables.startCameraPositionY, variables.startCameraPositionZ);
        camera.lookAt(variables.startCameraLookAtX, variables.startCameraLookAtY, variables.startCameraLookAtZ);
      }
    };

    const loadRenderer = () => {
      renderer = new THREE.WebGLRenderer({ antialias: antialias.value });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(pixelRatio.value); // Set the pixel ratio for High DPI screens
      renderer.shadowMap.enabled = shadowsEnabled.value; // Enable shadow maps in the renderer
      renderer.setClearColor(Number(variables.mainColor), 1); // Set the clear color
      if (gameBoard.value) {
        gameBoard.value.appendChild(renderer.domElement);
      }
    };
    const url = computed(() => {
      switch (store.getters.getPerformanceData.combinedRating) {
        case 4:
          return '/models/WegovyBoardLevelHighResolution/GameBoard - Highest Resolution.gltf';
        case 3:
          return '/models/WegovyBoardLevelMediumResolution/Wegovy-Board_Level-2_Medium-Resolution.gltf';
        case 2:
          return '/models/WegovyBoardLevelLowestResolution/GameBoard - Lowest Resolution.gltf';
        case 1:
        default:
          return '/models/WegovyBoardLevelLowestResolution/GameBoard - Lowest Resolution.gltf';
      }
    });
    const loadGameBoard =
        () => {
      gltfLoader.load(url.value, (gltf) => {
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.receiveShadow = shadowsEnabled.value;
            //if child.name to lower case contains 'space' or equals 'start' or equals 'finish' then child.receiveShadow = shadowsEnabled.value;
            if(child.name.toLowerCase().includes('space') || child.name.toLowerCase() === 'start' || child.name.toLowerCase() === 'finish') {
              child.receiveShadow = shadowsEnabled.value;
            }
          }
            if (child.isLight && child.type === 'DirectionalLight' && child.name === 'Directional_Light_Right') {
            child.intensity = 2;
          }
          if (child.isLight && child.type === 'SpotLight' && child.name === 'Directional_Light_Middle') {
            child.intensity = 0;
          }
          if (child.isLight && child.type === 'DirectionalLight' && child.name === 'Directional_Light_Left_(shadows)') {
            child.intensity = 1;
            child.castShadow = shadowsEnabled.value;
            if (shadowsEnabled.value) {
              child.shadow.mapSize.set(shadowSize.value, shadowSize.value); // Increase for better shadow resolution
              child.shadow.camera.near = 0.5;
              child.shadow.camera.far = 500;
              child.shadow.camera.left = -150;
              child.shadow.camera.right = 150;
              child.shadow.camera.top = 150;
              child.shadow.camera.bottom = -150;
              child.shadow.bias = -0.0001;
            }
          }
        });
        gameBoardGroup.add(gltf.scene);
        gameBoardGroup.scale.set(100, 100, 100);
        gameBoardGroup.position.set(0, 0, 0);
        scene.add(gameBoardGroup);
      });

      const planeGeometry = new THREE.PlaneGeometry(1000, 1000);
      const planeMaterial = new THREE.MeshStandardMaterial({ color: Number(variables.mainColor) });
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.rotation.x = -Math.PI / 2;
      plane.position.y = -0.1;
      plane.receiveShadow = shadowsEnabled.value;
      scene.add(plane);

      const ambientLight = new THREE.AmbientLight(Number(variables.ambientLightColor), variables.ambientLightIntensity);
      scene.add(ambientLight);
    };

    const loadGamePiece = () => {
      gltfLoader.load('/models/blueAnim/Blue Figure - NLA Animation_V5.gltf', (gltf) => {
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.material = new THREE.MeshStandardMaterial({ color: 0x66BED7, metalness: 0.1, roughness: 0.1 });
            child.castShadow = shadowsEnabled.value;
          }
        });

        mixer = new THREE.AnimationMixer(gltf.scene);
        action1 = mixer.clipAction(gltf.animations[1]);
        action2 = mixer.clipAction(gltf.animations[0]);
        action3 = mixer.clipAction(gltf.animations[2]);
        action1.enabled = true;
        action2.enabled = true;
        action3.enabled = true;
        action2.timeScale = 2.5;
        action1.play();
        gamePiece.add(gltf.scene);
      });
      const initialPosition = props.placesData[store.getters.getCurrentSpace];
      gamePiece.position.set(initialPosition.x, initialPosition.y, initialPosition.z);
      gamePiece.rotation.y = (initialPosition.angle + 180) * (Math.PI / 180);
      gamePiece.scale.set(1, 1, 1);
      scene.add(gamePiece);
    };

    const animate = () => {
      const elapsedTime = clock.getElapsedTime();
      const deltaTime = elapsedTime - previousTime;
      previousTime = elapsedTime;

      if (mixer) {
        mixer.update(deltaTime);
      }

      requestAnimationFrame(animate);
      TWEEN.update();
      renderer.render(scene, camera);
      renderer.shadowMap.enabled = shadowsEnabled.value;
    };

    const initThree = () => {
      scene = new THREE.Scene();
      loadEnvironmentMap();
      loadCamera();
      loadRenderer();
      loadGameBoard();
      loadGamePiece();
      animate();
    };

    const moveToSpace = (space) => {
      let startingSpace = store.getters.getCurrentSpace;
      if (space > 0 && space < 42 && startingSpace !== space) {
        setTimeout(() => {
          action2.play();
          props.soundUtility.playSound('footSteps', 0.2, true);
        }, variables.gamePieceMoveDuration);
        const moveInterval = setInterval(() => {
          if (startingSpace !== space) {
            startingSpace = startingSpace < space ? startingSpace + 1 : startingSpace - 1;
            zoomInCamera(startingSpace, variables.gamePieceMoveDuration);
            moveToGamePiece(startingSpace, variables.gamePieceMoveDuration);
          } else {
            clearInterval(moveInterval);
            props.soundUtility.stopSound('footSteps');
            if (startingSpace === props.placesData.length - 1) {
              emit('endGame');
              if (action1) action1.stop();
              if (action2) action2.stop();
              if (action3) action3.play();
            } else {
              emit('rollDone', startingSpace, variables.gamePieceMoveDuration);
              if (action2) action2.stop(); // Stop walking animation
            }
          }
        }, variables.gamePieceMoveDuration);
      }
    };

    const moveToGamePiece = (tile, duration, onComplete) => {
      if (tile < 0 || tile >= props.placesData.length) return; // Boundary check
      const targetTilePosition = props.placesData[tile];
      const targetGamePiecePosition = new THREE.Vector3(targetTilePosition.x, targetTilePosition.y, targetTilePosition.z); // Adjust Y as needed
      const rotationRadians = (targetTilePosition.angle + 180) * (Math.PI / 180);

      new TWEEN.Tween(gamePiece.rotation)
          .to({ y: rotationRadians }, duration)
          .easing(TWEEN.Easing.Linear.None)
          .start();

      new TWEEN.Tween(gamePiece.position)
          .to(targetGamePiecePosition, duration)
          .easing(TWEEN.Easing.Linear.None)
          .onComplete(() => {
            if (onComplete) onComplete();
          })
          .start();
    };

    const calculateCameraPosition = (place, isAnimating) => {
      const forward = new THREE.Vector3(0, 0, -1);
      forward.applyEuler(new THREE.Euler(0, THREE.MathUtils.degToRad(place.angle), 0, 'XYZ'));
      let distance = 0;
      if(store.getters.getGameEnded){
       distance = isAnimating ? variables.movingOffsetBehind : variables.endingOffsetBehind;
      }else{
       distance = isAnimating ? variables.movingOffsetBehind : variables.idleOffsetBehind;
      }
      const offset = forward.multiplyScalar(-distance);
      if(store.getters.getGameEnded){
        offset.y += isAnimating ? variables.movingOffsetAbove : variables.endingOffsetAbove;
      }else{
        offset.y += isAnimating ? variables.movingOffsetAbove : variables.idleOffsetAbove;
      }
      return new THREE.Vector3(place.x, place.y, place.z).add(offset);
    };

    const zoomInCamera = (tile, duration) => {
      const place = tile ? props.placesData[tile] : props.placesData[0];
      const targetPosition = calculateCameraPosition(place, store.getters.getIsAnimating);
      const targetLookAt = new THREE.Vector3(place.x, place.y, place.z);

      new TWEEN.Tween(camera.position)
          .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z }, duration)
          .easing(TWEEN.Easing.Linear.None)
          .start()
          .onComplete(() => {
            variables.startCameraPositionX = targetPosition.x;
            variables.startCameraPositionY = targetPosition.y;
            variables.startCameraPositionZ = targetPosition.z;
          });

      new TWEEN.Tween({
        lookX: variables.startCameraLookAtX,
        lookY: variables.startCameraLookAtY,
        lookZ: variables.startCameraLookAtZ,
      })
          .to({ lookX: targetLookAt.x, lookY: targetLookAt.y, lookZ: targetLookAt.z }, duration)
          .onUpdate(({ lookX, lookY, lookZ }) => {
            camera.lookAt(lookX, lookY, lookZ);
            variables.startCameraLookAtX = lookX;
            variables.startCameraLookAtY = lookY;
            variables.startCameraLookAtZ = lookZ;
          })
          .easing(TWEEN.Easing.Linear.None)
          .start();
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    watch(
        () => store.getters.getIsAnimating,
        () => {
          if (!store.getters.getIsAnimating) {
            if (store.getters.getGameStarted) {
              zoomInCamera(store.getters.getCurrentSpace, variables.gamePieceMoveDuration);
            }
          }
        }
    );

    watch(
        () => store.getters.getAnimatingToSpace,
        (newSpace) => {
          if (newSpace > 0 && newSpace < 42) {
            moveToSpace(newSpace);
          }
        }
    );

    watch(
        () => store.getters.getGameStarted,
        (newGameStarted) => {
          if (newGameStarted) {
            zoomInCamera(store.getters.getCurrentSpace, variables.initialZoomDuration);
            setTimeout(() => {
              store.dispatch('setAlreadyPlayed', true);
              emit('rollDone', 0);
            }, variables.initialZoomDuration);
          }
        }
    );

    onMounted(() => {
      if (props.placesData && props.placesData.length > 0) {
        initThree();
        if (store.getters.getAlreadyPlayed) {
          emit('rollDone', store.getters.getCurrentSpace);
          const place = props.placesData[store.getters.getCurrentSpace];
          const cameraPosition = calculateCameraPosition(place, false);

          camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
          camera.lookAt(place.x, place.y, place.z);
          variables.startCameraPositionX = cameraPosition.x;
          variables.startCameraPositionY = cameraPosition.y;
          variables.startCameraPositionZ = cameraPosition.z;
          variables.startCameraLookAtX = place.x;
          variables.startCameraLookAtY = place.y;
          variables.startCameraLookAtZ = place.z;

          gamePiece.position.set(place.x, place.y, place.z);
          gamePiece.rotation.y = THREE.MathUtils.degToRad(place.angle + 180);
        }
      }
      window.addEventListener('resize', onWindowResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onWindowResize);
    });
    return {
      gameBoard,
      loading,
      percentLoaded,
      store
    };
  },
};
</script>
<style scoped lang="scss">
#gameBoard {
  width: 100vh;
  height: 100%;
  height: 100dvh;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #D4E5EF radial-gradient(circle, #fff 0%, #D4E5EF 70%, #D4E5EF 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #460099;
  .loading-wrapper {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .text {
      font-size: 24px;
      margin-bottom: 20px;
      display: block;
    }
    .loading__progress {
      width: 100%;
      max-width: 300px;
      height: 20px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      .loading__progress-bar {
        width: 0;
        height: 100%;
        background-color: #460099;
      }
    }
  }
}
</style>

<template>
  <div class="compatibilityCheck">
    <div class="container">
      <img src="/images/Icon material-error-outline.png" alt="Logo" />
      <h1>Compatibility Check</h1>
      <p>Your browser does not support the required features to run this game.</p>
      <p>Please use a modern browser or upgrade to a version that supports the features above.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'CompatibilityCheck',
  emits: ['compatibility-result'],
  setup() {
    const store = useStore();
    const checks = ref({
      webGL2: false,
      webAudio: false,
      localStorage: false,
      promises: false,
      css3: false,
      serviceWorkers: false
    });

    onMounted(() => {
      const isCompatible = checkBrowserFeatures();
      store.dispatch('handleCompatibility', isCompatible);
    });

    function checkBrowserFeatures() {
      // Include all the check functions here
      checks.value.webGL2 = supportsWebGL2();
      checks.value.webAudio = supportsWebAudio();
      checks.value.localStorage = supportsLocalStorage();
      checks.value.promises = supportsPromises();
      checks.value.css3 = supportsAdvancedCSSFeatures();
      checks.value.serviceWorkers = supportsServiceWorkers();
      return Object.values(checks.value).every(check => check);
    }

    // Individual detection functions
    function supportsWebGL2() {
      try {
        const canvas = document.createElement('canvas');
        return !!window.WebGL2RenderingContext && (canvas.getContext('webgl2'));
      } catch (e) {
        return false;
      }
    }
    function supportsWebAudio() {
      return !!(window.AudioContext || window.webkitAudioContext);
    }
    function supportsLocalStorage() {
      try {
        const test = '__test__';
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch(e) {
        return false;
      }
    }
    function supportsPromises() {
      return 'Promise' in window;
    }
    function supportsTransforms() {
      return 'transform' in document.body.style;
    }
    function supportsTransitions() {
      return 'transition' in document.body.style;
    }
    function supportsAnimations() {
      return 'animation' in document.body.style;
    }
    function supportsCSSGrid() {
      return CSS.supports('display', 'grid');
    }
    function supportsCSSVariables() {
      return CSS.supports('color', 'var(--fake-var)');
    }
    function supportsFlexbox() {
      return CSS.supports('display', 'flex');
    }
    function supportsAdvancedCSSFeatures() {
      return supportsTransforms() && supportsTransitions() && supportsAnimations() &&
             supportsCSSGrid() && supportsCSSVariables() && supportsFlexbox();
    }

    function supportsServiceWorkers() {
      return 'serviceWorker' in navigator;
    }

    return { checks };
  }
}
</script>

<style lang="scss" scoped>
.compatibilityCheck {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  .container {
    display: table-cell;
    vertical-align: middle;
    padding: 20px;
    margin: auto;
    text-align: center;
    h1 {
      margin: 0 0 20px 0;
      font-size: 20px;
      color: #3F8CBA;
      width: 239px;
      margin: 10px auto;
    }

    p {
      margin: 10px 0;
      color: #4D4D4D;
      margin: 15px auto;
      width: 239px;
      font-size:16px;
      span{font-weight:700;}
    }

  }

}
</style>

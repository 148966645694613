<template>
  <div ref="statsWrapper" class="stats-wrapper">
    <div ref="stats" class="stats">
      <div class="stats-content" :class="{open:showStats}">
        <div class="stats-body">
          <div class="stats-info">
            <div class="stats-top">
              <h2>Your Stats</h2>
              <div class="correct-box">
                <img class="icon" src="@/images/check-flag.svg" alt="correct">
                <h3>{{numCorrect}} Correct</h3>
                <div class="card-boxes">
                  <div class="multiple-choice card-wrapper">
                    <h4>Multiple Choice</h4>
                    <div class="cards">
                      <div v-for="question in multipleChoice" :key="question.question" class="card multiple-choice" :class="{correct:question.isCorrect,incorrect:!question.isCorrect}"></div>
                    </div>
                  </div>
                  <div class="unscramble card-wrapper">
                    <h4>Unscramble</h4>
                    <div class="cards">
                      <div v-for="question in unscramble" :key="question.question" class="card unscramble" :class="{correct:question.isCorrect,incorrect:!question.isCorrect}"></div>
                    </div>
                  </div>
                  <div class="true-false card-wrapper">
                    <h4>True or False</h4>
                    <div class="cards">
                      <div v-for="question in trueFalse" :key="question.question" class="card true-false" :class="{correct:question.isCorrect,incorrect:!question.isCorrect}"></div>
                    </div>
                  </div>
                  <div class="fill-in-the-blank card-wrapper">
                    <h4>Fill-in-the-Blank</h4>
                    <div class="cards">
                      <div v-for="question in fillInTheBlank" :key="question.question" class="card fill-in-the-blank" :class="{correct:question.isCorrect,incorrect:!question.isCorrect}"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="stats-bottom">
              <h2>Your Final Score</h2>
              <div class="finalScore">
                <div class="yourScore" :class="{highscore:(store.getters.getCurrentScore >= store.getters.getQuestionSetHighScore)}">{{formatNumber(store.getters.getCurrentScore)}}</div>
              </div>
            </div>
          </div>
          <div class="stats-footer">
            <button @click="toggleStats">
              <span v-if="showStats">CLOSE <font-awesome-icon :icon="'circle-xmark'"></font-awesome-icon></span>
              <span v-else>SEE MY STATS <font-awesome-icon :icon="'circle-plus'"></font-awesome-icon></span>
            </button>
          </div>
        </div>
        <div class="stats-header">
          <h1>Congratulations</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="disclaimer">
    <div class="container">
      <p>Wegovy<sup>&reg;</sup> is a registered trademark of Novo Nordisk A/S.<br />Novo Nordisk is a registered trademark of Novo Nordisk A/S.<br />© {{getDateFromPRB()}} Novo Nordisk All rights reserved. {{store.getters.getPRB}}</p>
    </div>
  </div>
</template>
<script>
import {ref, onMounted, onUnmounted, toRaw} from 'vue';
import { useStore } from 'vuex';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'StatsComponent',
  components: {FontAwesomeIcon},
  setup() {
    const store = useStore();
    const statsWrapper = ref(null);
    const stats = ref(null);
    const showStats = ref(false);
    const numCorrect = ref(0);
    const multipleChoice = ref([]);
    const unscramble = ref([]);
    const trueFalse = ref([]);
    const fillInTheBlank = ref([]);
    const calculateNumberCorrect = () => {
      let questions = toRaw(store.getters.getSavedQuestions);
      let correct = 0;
      let count = 0;
      // Loop through each type of question
      Object.keys(questions).forEach(question => {
        // Get the array of questions for this type
        let questionArray = questions[question];
        if(questionArray.correct) {
          correct ++;
        }
        if(questionArray.type==='questions.true-false'){
          trueFalse.value.push({isCorrect: questionArray.correct, question: questionArray.uniqueId});
        }
        if(questionArray.type==='questions.multiple-choice'){
          multipleChoice.value.push({isCorrect: questionArray.correct, question: questionArray.uniqueId});
        }
        if(questionArray.type==='questions.unscramble'){
          unscramble.value.push({isCorrect: questionArray.correct, question: questionArray.uniqueId});
        }
        if(questionArray.type==='questions.fill-in-blank'){
          fillInTheBlank.value.push({isCorrect: questionArray.correct, question: questionArray.uniqueId});
        }
        count ++;
      });
      numCorrect.value = correct + '/' + count;
    };
    const toggleStats = () => {
      showStats.value = !showStats.value;
    };
    const getDateFromPRB = () => {
      if(store.getters.getPRB === null) return;
      //return last for digits of PRB
      return store.getters.getPRB.slice(-4);
    };
    const formatNumber = (value)=> {
      return new Intl.NumberFormat('en-US').format(value);
    };
    const resizeStats = () => {
      if(statsWrapper.value === null || stats.value === null) return;
      const cardWrapperWidth = statsWrapper.value.offsetWidth;
      const cardWrapperHeight = statsWrapper.value.offsetHeight;
      let margin = 30;
      if (cardWrapperWidth < 750) {
        margin = cardWrapperWidth * 0.04;
      }
      if (cardWrapperWidth < 750 && cardWrapperHeight < 990) {
        let wrapperAspect = cardWrapperWidth / cardWrapperHeight;
        if (wrapperAspect >= 750 / 990) {
          let scaleY = cardWrapperHeight / 990;
          stats.value.style.transform = 'scale(' + scaleY + ')';
        } else {
          let scaleX = (cardWrapperWidth - margin) / 750;
          stats.value.style.transform = 'scale(' + scaleX + ')';
        }
      } else if (cardWrapperWidth < 750 && cardWrapperHeight >= 990) {
        let scaleX = (cardWrapperWidth - margin) / 750;
        stats.value.style.transform = 'scale(' + scaleX + ')';
      } else if (cardWrapperHeight < 990 && cardWrapperWidth >= 750) {
        let scaleY = cardWrapperHeight / 990;
        stats.value.style.transform = 'scale(' + scaleY + ')';
      } else {
        stats.value.style.transform = 'scale(1)';
      }
    };

    onMounted(() => {
      setTimeout(() => {
        showStats.value = true;
      }, 2000);
      calculateNumberCorrect();
      resizeStats();
      window.addEventListener('resize', resizeStats);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeStats);
    });

    return { formatNumber,stats, store, statsWrapper, resizeStats, toggleStats, showStats, calculateNumberCorrect,numCorrect, multipleChoice, unscramble, trueFalse, fillInTheBlank,getDateFromPRB};
  }
}
</script>
<style scoped lang="scss">
.stats-wrapper{position: absolute; left: 50%; width: 100%; transform: translateX(-50%); max-width: 750px; display: flex; align-items: center; justify-content: center;
  .stats {width: 720px; height: 990px; transform-origin: 50% 50%; aspect-ratio: 990/720;
    .stats-content{width:580px;height:980px;position: relative;margin:0 auto;
      .stats-header{transition:transform 500ms 0s;background: url(@/images/stats-banner.svg);background-size:contain;background-repeat:no-repeat;width:750px;height:168px;position: relative;left:50%;top:20px;transform: translateX(-50%) scale(0);text-align: center;
        h1 {font-size: 55px;color: #fff;margin: 0;padding: 30px 0;}
      }
      .stats-body{transition:height 500ms 500ms;height:50px;width:100%;position: absolute;bottom:0;left:0;background: linear-gradient(to bottom,#fff, #C1C6C8);box-shadow: 0 0 20px rgba(0,0,0,0.2);border-radius: 10px;overflow: hidden;
        .stats-info{position: absolute;bottom:52px;width:100%;height:780px;box-sizing: border-box;padding:5px 30px;
          h2 {font-size:25px;font-weight: bold;text-transform: uppercase;color:#4D4D4D;}
          .correct-box{background: #EDEDED;border:1px solid #C1C6C8;padding:20px;border-radius:20px;position: relative;
            .icon{position: absolute;right:0;top:0;transform: translate(15%, -15%);}
            h3 {font-size: 40px;color: #4D4D4D;font-weight:700;margin: 0;padding:20px 0 0;}
          }
          .card-boxes{display: flex;flex-wrap: wrap;justify-content: space-between;align-items: flex-start;margin-top: 20px;
            .card-wrapper{width: 48%;height: 100%;display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;padding-top:10px;
              h4{font-size: 17px;color: #4D4D4D;font-weight:700;margin: 0;padding:10px 0;text-transform: uppercase;}
              .cards{display: flex;flex-wrap: wrap;justify-content: flex-start;align-items: flex-start;
                .card{width: 60px;height: 95px;margin: 5px;position: relative;
                  &:before {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-image: url(@/images/cardHeader.svg);background-size:100px;opacity: 0.5;}
                  &.multiple-choice{background-color: #0092BC;}
                  &.unscramble{background: #440099;}
                  &.true-false{background: #C1C6C8;}
                  &.fill-in-the-blank{background: #C6007E;}
                  &::after{content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
                  &.correct{&::after{background-image: url(@/images/correct-check.svg);background-repeat: no-repeat;background-position: center;}}
                  &.incorrect{&::after{background-image: url(@/images/incorrect-check.svg);background-repeat: no-repeat;background-position: center;}}

                }
              }
            }
          }
          .stats-bottom{padding-top:20px;
            h2 {margin-bottom:0;}
            .finalScore{position: relative;height: 136px;padding:16px 0;box-sizing: border-box;
              .yourScore{font-weight: 700;line-height:104px;font-size:46px;color: #4D4D4D;text-align: center;width:100%;height:104px;border-radius:52px;background: #EDEDED;box-sizing: border-box;position: relative;padding-right:65px;
                &.highscore{padding-left: 110px;
                  &::before{display:flex;align-items:center;line-height:30px;content:'NEW HIGH SCORE!';color:#fff;font-size:25px;font-weight:700;text-align:center;position: absolute;top: 2px;left: 2px;width:182.65px;height: 100px;background-image: url(@/images/NewHighScore.svg);background-size:100%;background-repeat: no-repeat;}
                }
              }
              &::after{content: '';position: absolute;top: 0;right: 0;width: 136px;height: 136px;background-image: url(@/images/stars.svg);background-size:100%;background-repeat: no-repeat;}
            }
          }
        }
        .stats-footer{width:100%;height:50px;position: absolute;bottom:0;left:0;border-top:1px solid #707070;
          button{width: 100%;height: 100%;background: transparent;border: none;border-radius: 0 0 10px 10px;font-size: 25px;font-weight:700;color: #4D4D4D;cursor: pointer;border-top:1px solid #EFEFEF;
            svg{margin-left: 10px;}
          }
        }
      }
      &.open{
        .stats-body{height:980px;transition:height 500ms 0s;}
        .stats-header{transform: translateX(-50%) scale(1);transition:transform 500ms 500ms;}
      }
    }
  }
}
.disclaimer{position: absolute;bottom: 0;width: 100%;background: hsla(0,0%,100%,.86);
  .container{text-align: center;
    p{color: #4D4D4D;margin:0;}
  }
}
@media screen and (max-aspect-ratio: 1/1) {
  .stats-wrapper {top: min(4vw, 30px);height: calc(100vh - min(34vw, 255px)); height: calc(100dvh - min(34vw, 255px));}
  .ui-card-overlay {height: calc(100vh - min(16vw, 120px));}
  .disclaimer{bottom: min(16vw, 120px);
    .container{
      p {padding-top:min(1vw,7.5px);font-size: min(2.667vw,20px);}
    }
  }
}
@media screen and (min-aspect-ratio: 1/1) {
  .stats-wrapper {top: min(4vh, 30px); height: calc(100vh - min(34vh, 255px));}
  .ui-card-overlay {height: calc(100vh - min(16vh, 120px));}
  .disclaimer{bottom: min(16vh, 120px);
    .container{
      p {padding-top:min(1vh,7.5px);font-size: min(2.667vh,20px);}
    }

  }
}
</style>
<template>
  <div class="error">
    <div class="content">
      <img v-if="!questionData.timedOut" src="@/images/incorrect-icon.svg" alt="Incorrect" />
      <img v-else src="@/images/clock-icon.svg" alt="Timed Out" />
      <h3><span v-if="questionData.timedOut">Timed Out!</span><span v-else>Incorrect</span></h3>
      <div v-if="(questionData.errors >= activityContent.NumAttempts && activityContent.NumAttempts!==0) || questionData.timedOut">
        <p v-if="questionData.timedOut">Sorry, you ran out of time.</p>
        <p v-else>Sorry, you have reached the maximum number of attempts.</p>
        <div v-if="answer" class="correctAnswer">
          <p>The correct answer was:<br /><span v-html="answer"></span></p>
        </div>
        <div v-if="answers.num" class="correctAnswer">
          <p v-if="answers.num>1">The correct answers were:<br /><span v-html="answers.string"></span></p>
          <p v-else>The correct answer was:<br /><span v-html="answers.string"></span></p>
        </div>
        <p>Please click the "Review" button below for additional information.</p>
      </div>
      <div v-else>
        <p>Sorry, that is incorrect.</p>
        <p>Please click the "Try Again" button below.</p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ErrorComponent',
  props: {
    questionData: {
      type: Object,
      required: true
    },
    activityContent: {
      type: Object,
      required: true
    },
    answers: {
      type: Object,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  },
}
</script>
<style scoped lang="scss">
.error{position: relative;height:100%;display: flex;align-items: center;justify-content: center;text-align: center;width:100%;
  .content{position: relative;z-index: 1;width:100%;
    h3{font-size: 80px;font-weight: 700;color: #C6007E;margin:20px 0;}
    p{font-size: 25px;font-weight: 700;color: #4D4D4D;margin:20px auto;display: block;width:520px;}
    .correctAnswer{padding:20px 0;background:rgba(#FF0000,0.08);
      p{font-weight:500;
        span{font-weight:700;}
      }
    }
  }
}
</style>
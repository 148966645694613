<template>
    <CodeComponent v-if="state === 'code'" @validated="handleValidation" @validationError="handleError" />
    <ErrorComponent v-if="state === 'error'" :errorMessage="errorMessage" />
    <GameComponent v-if="state === 'validated'" />
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { toRaw } from 'vue';
import CodeComponent from './validation/CodeComponent.vue';
import ErrorComponent from './validation/ErrorComponent.vue';
import GameComponent from './game/GameComponent.vue';
import { useValidation } from '@/mixins/useValidation';

export default {
  name: 'EnvironmentComponent',
  components: {
    CodeComponent,
    ErrorComponent,
    GameComponent
  },
  setup() {
    const store = useStore();
    const {code, error, errorMessage, validateHash, hash, validateStorage} = useValidation();
    const state = ref('');
    async function checkValidation() {
      if (toRaw(store.state.game) && store.getters.getToken === hash.value) {
        validateCache();
      } else if (hash.value) {
        const isValid = await validateHash();
        if (isValid) {
          store.dispatch('resetGame');
          handleValidation();
        } else {
          state.value = 'error';
          handleError(errorMessage.value);
        }
      } else {
        handleError('No hash found in URL.');
      }
    }

    function handleValidation() {
      state.value = 'validated';
    }

    function handleError(msg) {
      error.value = true;
      errorMessage.value = msg;
    }

    async function validateCache() {
      const isValid = await validateStorage(toRaw(store.state.jwt),toRaw(store.state.game.updatedAt),toRaw(store.state.game.QuestionSet.updatedAt));
      if(isValid) {
        handleValidation();
      }else{
        store.dispatch('resetGameData');
      }
    }

    onMounted(() => {
      if (!hash.value) {
        state.value = 'code'; // Show code input if no hash is present
        if (toRaw(store.state.game) && toRaw(store.state.token)) {
          validateCache();
        }
      } else {
        checkValidation(); // Check hash validity
      }
    });

    return {state, errorMessage, handleValidation, handleError, code, error,validateStorage};
  }
}
</script>

<template>
  <div class="ui-progress" v-show="gameStarted && !gameEnded">
    <div class="text">Your Progress</div>
    <div class="progress-group">
      <div id="progressBar">
        <div :style="{width: progress + '%'}"></div>
      </div>
      <div class="progressIcon" :style="{left: progress + '%'}"></div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  setup() {
    const store = useStore();
    const gameStarted = computed(() => store.getters.getGameStarted);
    const progress = computed(() => store.getters.getProgress);
    const gameEnded = computed(() => store.getters.getGameEnded);
    return { gameStarted, progress, gameEnded };
  }
}
</script>

<style scoped lang="scss">
.ui-progress {position: absolute;background-image: url(@/images/progressBg.svg);background-size: cover;display: flex;flex-direction: column; justify-content: center;align-items: center;box-sizing:border-box;
  .text {color: #4D4D4D;font-weight: normal;}
  .progress-group {position: relative;height: 20%;
    #progressBar {background: #C1C5C7;width:100%;overflow: hidden;height: 100%;position: relative;
      div {height: 100%;background: #C6007E;transition: width 1s;position: absolute;top: 0;left: 0;}
    }
    .progressIcon {transition: left 1s;background: #fff url(@/images/ionic-ios-body.svg) no-repeat;background-position:center center;background-size:50%;box-shadow:0 0 10px rgba(0,0,0,0.16);border-radius: 50%;position: absolute;top: 50%;transform: translate(-50%,-50%);pointer-events: none;}
  }
}
@media screen and (max-aspect-ratio: 1/1) {
  .ui-progress {right:min(2vw,15px);bottom:min(18vw,135px);width:min(60vw,450px);height:min(20vw,150px);
    .text {font-size:min(2.916vw,22px);padding-bottom:min(2vw,15px);}
    .progress-group {width: calc(100% - min(16vw,120px));margin-bottom: min(1vw,7.5px);
      #progressBar {border-radius: min(2vw,15px);}
      .progressIcon {width: min(7vw,52.5px);height: min(7vw,52.5px);}
    }
  }
}
@media screen and (min-aspect-ratio: 1/1) {
  .ui-progress {right:min(2vh,15px);bottom:min(18vh,135px);bottom:min(18dvh,135px);width:min(60vh,450px);width:min(60dvh,450px);height:min(20vh,150px);height:min(20dvh,150px);
    .text {font-size:min(2.916vh,22px);padding-bottom:min(2vh,15px);}
    .progress-group {width: calc(100% - min(16vh,120px));margin-bottom: min(1vh,7.5px);
      #progressBar {border-radius: min(2vh,15px);}
      .progressIcon {width: min(7vh,52.5px);height: min(7vh,52.5px);}
    }
  }
}
</style>

import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

export const CardMixin = {
    props: {
        activityContent: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const questionData = computed(() => store.getters.getQuestion(props.activityContent.uniqueId));
        const gameType = computed(() => store.getters.getGameType);
        const createQuestionData = () => {
            if(props.activityContent){
                if (!questionData.value) {
                    const data = {
                        uniqueId: props.activityContent.uniqueId,
                        type: props.activityContent.__component,
                        roll: store.getters.getRoll,
                        timer: props.activityContent.TimeLimitSeconds,
                        answer: '',
                        answers: [],
                        lastAnswers: [],
                        points: null,
                        correct: false,
                        interacted: false,
                        timedOut: false,
                        errors: 0,
                        startTime: null,
                        endTime: null,
                        showError: false,
                        showCorrect: false,
                        showReview: false,
                        seenReview: false,
                        inError:[],
                        inCorrect:[],
                        hasError: false,
                    };
                    store.dispatch('setQuestion', data);
                }
            }
        };
        const saveToDatabase = (data) => {
            if (window.Worker) {
                const worker = new Worker(new URL('@/saveWorker.js', import.meta.url));
                const jwtToken = store.getters.getJWT;
                const plainData = JSON.parse(JSON.stringify(data));
                worker.postMessage({ jwtToken, data: plainData });

                worker.onmessage = function (event) {
                    if (!event.data.success) {
                        console.error('Error saving data:', event.data.error);
                    }
                };
            } else {
                console.error('Web Workers are not supported in this browser.');
            }
        };
        const createSaveData = () => {
            let answer = '';
            let EndTime = 0;
            let score = 0;
            if(questionData.value.points){
                score = questionData.value.points;
            }
            if(questionData.value.endTime){
                EndTime = questionData.value.endTime;
            }else {
                EndTime = Date.now();
            }
            if(questionData.value.answer !== ''){
                answer = questionData.value.answer;
            }else{
                answer = questionData.value.lastAnswers;
            }
            if (questionData.value) {
                const data = {
                    data: {
                        Game: store.getters.getGameID,
                        QuestionId: props.activityContent.id,
                        QuestionType: props.activityContent.__component,
                        TimedOut: questionData.value.timedOut,
                        IsCorrect: questionData.value.correct,
                        StartTime: questionData.value.startTime,
                        EndTime: EndTime,
                        Score: score,
                        AttemptData: {
                            answer:answer
                        }
                    }
                };
                saveToDatabase(data);
            }
        };
        const calculatePoints = () => {

            let calculatedPoints = 0;
            if(store.getters.getGameType === 'Random 1' || store.getters.getGameType === 'Random 2'){
                if(questionData.value.timedOut){
                    calculatedPoints = 0;
                }else{
                    if(!questionData.value.correct){
                        calculatedPoints = 0;
                    }else{
                        //calculate percentage score based on number of attempts vs number of questionData.value.errors.  If number of attempts is 1 and number of errors is 0, then 100% score
                        if(questionData.value.errors === 0) {
                            calculatedPoints = 100;
                        }else{
                            if(props.activityContent.NumAttempts){
                                calculatedPoints = Math.round(((props.activityContent.NumAttempts - questionData.value.errors) * 100) / props.activityContent.NumAttempts);
                            }else{
                                calculatedPoints = Math.round((100 / parseInt(questionData.value.errors+1)));
                            }

                        }
                    }
                }
            }else{
                let points = 10;
                let multiplier = 1;

                if(questionData.value.timedOut || (props.activityContent.NumAttempts > 0 && questionData.value.errors === props.activityContent.NumAttempts)){
                    points = points - 7;
                } else {
                    if(questionData.value.errors === 1){
                        points = points - 5;
                    }
                    let totalMilliseconds = props.activityContent.TimeLimitSeconds * 1000;
                    let millisecondsLeft = totalMilliseconds-((questionData.value.endTime - questionData.value.startTime));
                    questionData.value.endTime;
                    multiplier = Math.round((millisecondsLeft/totalMilliseconds)*1000);
                }
                calculatedPoints = points * multiplier;
            }
            questionData.value.points = calculatedPoints;
            store.dispatch('setPoints', { title:  props.activityContent.uniqueId, points: calculatedPoints });
        };
        const updateQuestionDataInStore = (data) => {
            store.dispatch('setQuestion', data);
        };
        onMounted(createQuestionData);
        return {
            questionData,
            updateQuestionDataInStore,
            calculatePoints,
            createSaveData,
            saveToDatabase,
            gameType
        };
    }
};

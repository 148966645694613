<template>
  <div class="errorPage">
    <div class="container">
      <h1>Error</h1>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorComponent',
  props: {
    errorMessage: String
  }
}
</script>
<style lang="scss" scoped>
.errorPage {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  .container {
    display: table-cell;
    vertical-align: middle;
    padding: 20px;


    margin: auto;
    text-align: center;
    h1 {
      margin: 0 0 20px 0;
      font-size: min(5vw,50px);
      color: #3F8CBA;
      width: 239px;
      margin: 10px auto;
    }

    p {
      margin: 10px 0;
      color: #4D4D4D;
      margin: 15px auto;
      width: 239px;
      font-size:min(3vw,20px);
      span{font-weight:700;}
    }

  }

}
</style>

<template>
  <div v-if="gameStarted && !gameEnded" class="ui-points">
    <div class="points-top">
      <span>Best Score</span>
      <span class="score" v-if="questionSetHighScore>currentScore">{{ formatNumber(questionSetHighScore,gameType) }}</span>
      <span class="score" v-else>{{ formatNumber(currentScore.toFixed(0),gameType) }}</span>
    </div>
    <div class="points-bottom">
      <span>Your Score</span>
      <span class="score">{{ formatNumber(currentScore.toFixed(0),gameType) }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const gameStarted = computed(() => store.getters.getGameStarted);
    const gameEnded = computed(() => store.getters.getGameEnded);
    const questionSetHighScore = computed(() => store.getters.getQuestionSetHighScore);
    const currentScore = computed(() => store.getters.getCurrentScore);
    const gameType = computed(() => store.getters.getGameType);
    const formatNumber = (value,gameType)=> {
      let formattedNumber = new Intl.NumberFormat('en-US').format(value);
      if(gameType ==='Random 1' || gameType ==='Random 2'){
        formattedNumber+='%';
      }
      return formattedNumber;
    };
    return { gameStarted, gameEnded, questionSetHighScore, currentScore,formatNumber,gameType };
  }
}
</script>

<style scoped lang="scss">
.ui-points {position: absolute;top: 0;background-size: cover;background-image: url(@/images/scoreBoardBG.svg);text-align: center;
  .points-top {width: 100%;
    span {display: block;font-weight: 400;color: #555555;
      &.score {font-weight: 700;color: #4D4D4D;}
    }
  }
  .points-bottom {width: 100%;position: absolute;left: 0;
    span {display: block;font-weight: 400;color: #fff;
      &.score {font-weight: 700;color: #fff;}
    }
  }
}
@media screen and (max-aspect-ratio: 1/1) {
  .ui-points {left: min(2vw,15px);height: min(17.5vw,131.25px);width: min(25vw,187.5px);
    .points-top {width: 100%;
      span {font-size: min(1.856vw,14px);line-height: min(2.667vw,20px);padding-top: min(0.5vw,3.75px);
        &.score {font-size: min(2.916vw,22px);line-height: min(2.916vw,22px);padding-top: 0;}
      }
    }
    .points-bottom {top: min(6vw,45px);
      span {font-size: min(2.4vw,18px);line-height: min(2.4vw,18px);padding-top: min(1.5vw,11.25px);
        &.score {font-size: min(3.5vw,26.25px);line-height: min(3.5vw,26.25px);padding-top: min(0.5vw,3.75px);}
      }
    }
  }
}
@media screen and (min-aspect-ratio: 1/1) {
  .ui-points {left: min(2vh,15px);height: min(17.5vh,131.25px);width: min(25vh,187.5px);
    .points-top {width: 100%;
      span {font-size: min(1.856vh,14px);line-height: min(2.667vh,20px);padding-top: min(0.5vh,3.75px);
        &.score {font-size: min(2.916vh,22px);line-height: min(2.916vh,22px);padding-top: 0;}
      }
    }
    .points-bottom {top: min(6vh,45px);
      span {font-size: min(2.4vh,18px);line-height: min(2.4vh,18px);padding-top: min(1.5vh,11.25px);
        &.score {font-size: min(3.5vh,26.25px);line-height: min(3.5vh,26.25px);padding-top: min(0.5vh,3.75px);}
      }
    }
  }
}
</style>

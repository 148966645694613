import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faVolumeUp, faVolumeMute, faClose, faCaretUp, faAngleRight, faArrowLeft, faAngleDown, faDeleteLeft, faCirclePlus,faCircleXmark} from '@fortawesome/free-solid-svg-icons'
library.add(faVolumeUp, faVolumeMute, faClose, faCaretUp, faAngleRight, faArrowLeft, faAngleDown, faDeleteLeft, faCircleXmark, faCirclePlus)
document.addEventListener('dblclick', function(event) {
    event.preventDefault();
}, { passive: false });
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store);
app.mount('#app');

<template>
  <svg v-if="gameEnded" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1974" height="1053" preserveAspectRatio="xMidYMid" viewBox="0 0 1974 1053">
    <g transform="translate(987,526.5) scale(1,1) translate(-987,-526.5)">
      <g transform="translate(0 415.419839398878)"><g transform="translate(1841.8264203468282 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.0871339364547716s;animation-duration:4s">
          <rect x="-4.924233913921766" y="-5.159081986137705" width="9.848467827843532" height="10.31816397227541" style="transform:scale(1.6578023831898105)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1339.5367920716117 0)">
        <g class="ld ld-fall2" style="animation-delay:-2.6110593955814205s;animation-duration:4s">
          <rect x="-5.462248484318388" y="-3.6372262488550744" width="10.924496968636776" height="7.274452497710149" style="transform:scale(1.846385806493522)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1571.1766456437215 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.9654335739079012s;animation-duration:2s">
          <rect x="-4.347715132417964" y="-5.775412594328906" width="8.695430264835927" height="11.550825188657813" style="transform:scale(3.2151271256274985)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1308.6279792322507 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.40936409800423523s;animation-duration:2s">
          <rect x="-5.2495757749242875" y="-3.0642253338216587" width="10.499151549848575" height="6.1284506676433175" style="transform:scale(2.4882530644984584)" fill="#f7d038"></rect>
        </g></g><g transform="translate(1175.5608022279341 0)">
        <g class="ld ld-fall2" style="animation-delay:-3.564223124049296s;animation-duration:4s">
          <rect x="-3.208952734450018" y="-3.4501296846621337" width="6.417905468900036" height="6.900259369324267" style="transform:scale(3.2756705380070694)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(1308.3826907736711 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.1373429756588087s;animation-duration:4s">
          <rect x="-3.188018316337782" y="-3.155644646584599" width="6.376036632675564" height="6.311289293169198" style="transform:scale(3.109702332555559)" fill="#f7d038"></rect>
        </g></g><g transform="translate(878.4949158768126 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.6372684545889586s;animation-duration:2s">
          <rect x="-3.2013052865546356" y="-5.913105337900367" width="6.402610573109271" height="11.826210675800734" style="transform:scale(2.5866390813753797)" fill="#d23be7"></rect>
        </g></g><g transform="translate(327.3316371575406 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.8069276360418265s;animation-duration:2s">
          <rect x="-3.9346374434589615" y="-4.801046709658012" width="7.869274886917923" height="9.602093419316024" style="transform:scale(1.6590013617669759)" fill="#a3e048"></rect>
        </g></g><g transform="translate(1491.019641479963 0)">
        <g class="ld ld-fall2" style="animation-delay:-3.0584734476493525s;animation-duration:4s">
          <rect x="-3.300605616228758" y="-4.67944322488862" width="6.601211232457516" height="9.35888644977724" style="transform:scale(1.9829534399665547)" fill="#d23be7"></rect>
        </g></g><g transform="translate(1573.8772579312638 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.58501190585201s;animation-duration:2s">
          <rect x="-3.6667906220481443" y="-3.854213134657128" width="7.333581244096289" height="7.708426269314256" style="transform:scale(2.9902152163776967)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(116.60050367189501 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.534453127167585s;animation-duration:2s">
          <rect x="-3.51217056629768" y="-4.6980549343592966" width="7.02434113259536" height="9.396109868718593" style="transform:scale(2.6272972692203966)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1397.2471011822283 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.3385954613018365s;animation-duration:4s">
          <rect x="-3.9091476418080453" y="-5.678317522553853" width="7.818295283616091" height="11.356635045107707" style="transform:scale(2.3222919995079296)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(880.3133880441558 0)">
        <g class="ld ld-fall2" style="animation-delay:-3.0482430919540566s;animation-duration:4s">
          <rect x="-3.316285565408618" y="-5.579078297419416" width="6.632571130817236" height="11.158156594838832" style="transform:scale(1.9550200006424345)" fill="#d23be7"></rect>
        </g></g><g transform="translate(152.398075956006 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.13138807839797906s;animation-duration:4s">
          <rect x="-5.086157280247459" y="-4.714100198912069" width="10.172314560494918" height="9.428200397824138" style="transform:scale(3.44452096352762)" fill="#4355db"></rect>
        </g></g><g transform="translate(729.0334033910835 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.4372378303838482s;animation-duration:2s">
          <rect x="-3.3862097640028037" y="-3.6934847447844144" width="6.772419528005607" height="7.386969489568829" style="transform:scale(3.139633166775535)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1761.986907863099 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.1292562915267412s;animation-duration:2s">
          <rect x="-3.9861397101720897" y="-3.6525206641148174" width="7.972279420344179" height="7.305041328229635" style="transform:scale(2.1714587764261624)" fill="#4355db"></rect>
        </g></g><g transform="translate(1300.811223076719 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.7689108465522017s;animation-duration:4s">
          <rect x="-5.327688885866154" y="-3.880936350012775" width="10.655377771732308" height="7.76187270002555" style="transform:scale(2.7612709250916767)" fill="#4355db"></rect>
        </g></g><g transform="translate(439.51411078850316 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.825170902510457s;animation-duration:2s">
          <rect x="-3.1736487380671408" y="-3.620636045922821" width="6.3472974761342815" height="7.241272091845642" style="transform:scale(1.697903236644325)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(1592.7882785471033 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.21606569160330746s;animation-duration:4s">
          <rect x="-4.923463829247755" y="-4.222232067157498" width="9.84692765849551" height="8.444464134314996" style="transform:scale(1.6427404656372926)" fill="#f7d038"></rect>
        </g></g><g transform="translate(533.2306709997517 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.5157662770502767s;animation-duration:4s">
          <rect x="-4.774999593483283" y="-5.05288268079996" width="9.549999186966566" height="10.10576536159992" style="transform:scale(2.7327931136470496)" fill="#a3e048"></rect>
        </g></g><g transform="translate(1635.1831563189692 0)">
        <g class="ld ld-fall2" style="animation-delay:-0.17664893604839627s;animation-duration:2s">
          <rect x="-3.7010865926141587" y="-5.753839084987215" width="7.4021731852283175" height="11.50767816997443" style="transform:scale(3.277289700951358)" fill="#4355db"></rect>
        </g></g><g transform="translate(1570.3810866951828 0)">
        <g class="ld ld-fall2" style="animation-delay:-2.386741937794056s;animation-duration:4s">
          <rect x="-3.8253011319076555" y="-4.109926629158662" width="7.650602263815311" height="8.219853258317324" style="transform:scale(2.1556506230339743)" fill="#f7d038"></rect>
        </g></g><g transform="translate(624.1085306943097 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.9805135185463065s;animation-duration:4s">
          <rect x="-5.357008139724039" y="-5.079525473879163" width="10.714016279448078" height="10.159050947758326" style="transform:scale(2.1771736329247577)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1221.3215517532421 0)">
        <g class="ld ld-fall2" style="animation-delay:-2.3554910732609358s;animation-duration:4s">
          <rect x="-3.3560475511617733" y="-5.387287713728714" width="6.712095102323547" height="10.774575427457428" style="transform:scale(3.456758597324445)" fill="#d23be7"></rect>
        </g></g><g transform="translate(934.7233245877437 0)">
        <g class="ld ld-fall2" style="animation-delay:-1.9136775828831005s;animation-duration:2s">
          <rect x="-3.353468532428547" y="-5.709171960080894" width="6.706937064857094" height="11.418343920161789" style="transform:scale(2.2649852746857766)" fill="#34bbe6"></rect>
        </g></g></g>
      <g transform="translate(0 -649.580160601122)"><g transform="translate(1841.8264203468282 0)">
        <g class="ld ld-fall" style="animation-delay:-1.0871339364547716s;animation-duration:4s">
          <rect x="-4.924233913921766" y="-5.159081986137705" width="9.848467827843532" height="10.31816397227541" style="transform:scale(1.6578023831898105)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1339.5367920716117 0)">
        <g class="ld ld-fall" style="animation-delay:-2.6110593955814205s;animation-duration:4s">
          <rect x="-5.462248484318388" y="-3.6372262488550744" width="10.924496968636776" height="7.274452497710149" style="transform:scale(1.846385806493522)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1571.1766456437215 0)">
        <g class="ld ld-fall" style="animation-delay:-0.9654335739079012s;animation-duration:2s">
          <rect x="-4.347715132417964" y="-5.775412594328906" width="8.695430264835927" height="11.550825188657813" style="transform:scale(3.2151271256274985)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1308.6279792322507 0)">
        <g class="ld ld-fall" style="animation-delay:-0.40936409800423523s;animation-duration:2s">
          <rect x="-5.2495757749242875" y="-3.0642253338216587" width="10.499151549848575" height="6.1284506676433175" style="transform:scale(2.4882530644984584)" fill="#f7d038"></rect>
        </g></g><g transform="translate(1175.5608022279341 0)">
        <g class="ld ld-fall" style="animation-delay:-3.564223124049296s;animation-duration:4s">
          <rect x="-3.208952734450018" y="-3.4501296846621337" width="6.417905468900036" height="6.900259369324267" style="transform:scale(3.2756705380070694)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(1308.3826907736711 0)">
        <g class="ld ld-fall" style="animation-delay:-0.1373429756588087s;animation-duration:4s">
          <rect x="-3.188018316337782" y="-3.155644646584599" width="6.376036632675564" height="6.311289293169198" style="transform:scale(3.109702332555559)" fill="#f7d038"></rect>
        </g></g><g transform="translate(878.4949158768126 0)">
        <g class="ld ld-fall" style="animation-delay:-0.6372684545889586s;animation-duration:2s">
          <rect x="-3.2013052865546356" y="-5.913105337900367" width="6.402610573109271" height="11.826210675800734" style="transform:scale(2.5866390813753797)" fill="#d23be7"></rect>
        </g></g><g transform="translate(327.3316371575406 0)">
        <g class="ld ld-fall" style="animation-delay:-1.8069276360418265s;animation-duration:2s">
          <rect x="-3.9346374434589615" y="-4.801046709658012" width="7.869274886917923" height="9.602093419316024" style="transform:scale(1.6590013617669759)" fill="#a3e048"></rect>
        </g></g><g transform="translate(1491.019641479963 0)">
        <g class="ld ld-fall" style="animation-delay:-3.0584734476493525s;animation-duration:4s">
          <rect x="-3.300605616228758" y="-4.67944322488862" width="6.601211232457516" height="9.35888644977724" style="transform:scale(1.9829534399665547)" fill="#d23be7"></rect>
        </g></g><g transform="translate(1573.8772579312638 0)">
        <g class="ld ld-fall" style="animation-delay:-0.58501190585201s;animation-duration:2s">
          <rect x="-3.6667906220481443" y="-3.854213134657128" width="7.333581244096289" height="7.708426269314256" style="transform:scale(2.9902152163776967)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(116.60050367189501 0)">
        <g class="ld ld-fall" style="animation-delay:-0.534453127167585s;animation-duration:2s">
          <rect x="-3.51217056629768" y="-4.6980549343592966" width="7.02434113259536" height="9.396109868718593" style="transform:scale(2.6272972692203966)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1397.2471011822283 0)">
        <g class="ld ld-fall" style="animation-delay:-0.3385954613018365s;animation-duration:4s">
          <rect x="-3.9091476418080453" y="-5.678317522553853" width="7.818295283616091" height="11.356635045107707" style="transform:scale(2.3222919995079296)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(880.3133880441558 0)">
        <g class="ld ld-fall" style="animation-delay:-3.0482430919540566s;animation-duration:4s">
          <rect x="-3.316285565408618" y="-5.579078297419416" width="6.632571130817236" height="11.158156594838832" style="transform:scale(1.9550200006424345)" fill="#d23be7"></rect>
        </g></g><g transform="translate(152.398075956006 0)">
        <g class="ld ld-fall" style="animation-delay:-0.13138807839797906s;animation-duration:4s">
          <rect x="-5.086157280247459" y="-4.714100198912069" width="10.172314560494918" height="9.428200397824138" style="transform:scale(3.44452096352762)" fill="#4355db"></rect>
        </g></g><g transform="translate(729.0334033910835 0)">
        <g class="ld ld-fall" style="animation-delay:-1.4372378303838482s;animation-duration:2s">
          <rect x="-3.3862097640028037" y="-3.6934847447844144" width="6.772419528005607" height="7.386969489568829" style="transform:scale(3.139633166775535)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1761.986907863099 0)">
        <g class="ld ld-fall" style="animation-delay:-1.1292562915267412s;animation-duration:2s">
          <rect x="-3.9861397101720897" y="-3.6525206641148174" width="7.972279420344179" height="7.305041328229635" style="transform:scale(2.1714587764261624)" fill="#4355db"></rect>
        </g></g><g transform="translate(1300.811223076719 0)">
        <g class="ld ld-fall" style="animation-delay:-0.7689108465522017s;animation-duration:4s">
          <rect x="-5.327688885866154" y="-3.880936350012775" width="10.655377771732308" height="7.76187270002555" style="transform:scale(2.7612709250916767)" fill="#4355db"></rect>
        </g></g><g transform="translate(439.51411078850316 0)">
        <g class="ld ld-fall" style="animation-delay:-1.825170902510457s;animation-duration:2s">
          <rect x="-3.1736487380671408" y="-3.620636045922821" width="6.3472974761342815" height="7.241272091845642" style="transform:scale(1.697903236644325)" fill="#34bbe6"></rect>
        </g></g><g transform="translate(1592.7882785471033 0)">
        <g class="ld ld-fall" style="animation-delay:-0.21606569160330746s;animation-duration:4s">
          <rect x="-4.923463829247755" y="-4.222232067157498" width="9.84692765849551" height="8.444464134314996" style="transform:scale(1.6427404656372926)" fill="#f7d038"></rect>
        </g></g><g transform="translate(533.2306709997517 0)">
        <g class="ld ld-fall" style="animation-delay:-1.5157662770502767s;animation-duration:4s">
          <rect x="-4.774999593483283" y="-5.05288268079996" width="9.549999186966566" height="10.10576536159992" style="transform:scale(2.7327931136470496)" fill="#a3e048"></rect>
        </g></g><g transform="translate(1635.1831563189692 0)">
        <g class="ld ld-fall" style="animation-delay:-0.17664893604839627s;animation-duration:2s">
          <rect x="-3.7010865926141587" y="-5.753839084987215" width="7.4021731852283175" height="11.50767816997443" style="transform:scale(3.277289700951358)" fill="#4355db"></rect>
        </g></g><g transform="translate(1570.3810866951828 0)">
        <g class="ld ld-fall" style="animation-delay:-2.386741937794056s;animation-duration:4s">
          <rect x="-3.8253011319076555" y="-4.109926629158662" width="7.650602263815311" height="8.219853258317324" style="transform:scale(2.1556506230339743)" fill="#f7d038"></rect>
        </g></g><g transform="translate(624.1085306943097 0)">
        <g class="ld ld-fall" style="animation-delay:-1.9805135185463065s;animation-duration:4s">
          <rect x="-5.357008139724039" y="-5.079525473879163" width="10.714016279448078" height="10.159050947758326" style="transform:scale(2.1771736329247577)" fill="#e6261f"></rect>
        </g></g><g transform="translate(1221.3215517532421 0)">
        <g class="ld ld-fall" style="animation-delay:-2.3554910732609358s;animation-duration:4s">
          <rect x="-3.3560475511617733" y="-5.387287713728714" width="6.712095102323547" height="10.774575427457428" style="transform:scale(3.456758597324445)" fill="#d23be7"></rect>
        </g></g><g transform="translate(934.7233245877437 0)">
        <g class="ld ld-fall" style="animation-delay:-1.9136775828831005s;animation-duration:2s">
          <rect x="-3.353468532428547" y="-5.709171960080894" width="6.706937064857094" height="11.418343920161789" style="transform:scale(2.2649852746857766)" fill="#34bbe6"></rect>
        </g></g></g></g>
  </svg>
</template>
<script>
export default {
  props: {
    gameEnded: Boolean
  }
}
</script>
<style scoped lang="scss">
  @media screen and (max-aspect-ratio: 1/1) {
    svg {margin:auto;display:block;position:absolute;width:auto;height:100%;top:0;left:0;}
  }
  @media screen and (min-aspect-ratio: 1/1) {
    svg {margin:auto;display:block;position:absolute;width:100%;height:auto;top:0;left:0;}
  }
  @keyframes ld-fall {
    0%    { transform: translate(0,0px) rotate3d(1,1,1,0deg); }
    16.6% { transform: translate(0,177.5px) rotate3d(1,1,1,180deg); }
    33.3% { transform: translate(0,355px) rotate3d(1,1,1,360deg); }
    50.0% { transform: translate(0,532.5px) rotate3d(1,1,1,540deg); }
    66.6% { transform: translate(0,710px) rotate3d(1,1,1,720deg); }
    83.3% { transform: translate(0,887.5px) rotate3d(1,1,1,900deg); }
    100%  { transform: translate(0,1065px) rotate3d(1,1,1,1080deg) ; }
  }
  .ld.ld-fall {
    animation: ld-fall 4s linear infinite;
  }
  .ld.ld-fall2 {
    animation: ld-fall 4s linear infinite;
    animation-fill-mode: forwards;
  }
</style>
<template>
  <div class="enter-code">
    <form @submit.prevent="submitCode">
      <label>Please enter your code:</label>
      <input v-model="code" maxlength="8" required />
      <p v-if="error">{{ errorMessage }}</p>
      <button type="submit">Enter  <font-awesome-icon :icon="'angle-right'" /></button>
    </form>

  </div>
</template>

<script>
import {useValidation} from '@/mixins/useValidation';
export default {
  name: 'CodeComponent',
  setup(_, {emit}) {
    const {code, error, errorMessage, validateCode} = useValidation();

    async function submitCode() {
      let code = await validateCode();
      if (code) {
        // redirect to main url with code at end
        //strip any existing paths or query strings for the window.location.href
       // run the function handleValidation from the EnvironmentComponent
        emit('validated');
      } else {
        emit('validationError');
      }
    }

    return {code, error, errorMessage, submitCode};
  }
}
</script>
<style lang="scss" scoped>
.enter-code{position: fixed;top:0;left: 0;width: 100%;height: 100%;height: 100dvh;display: flex;justify-content: center;align-items: center;
  form{width:238px;text-align:center;
    label{display:block;font-weight: 700;color:#3F8CBA;font-size:20px;padding:0 0 30px;}
    input{width:100%;padding:10px 0;margin:0 0 15px;border:1px solid #3F8CBA;text-align: center;-webkit-appearance:none;}
    p{color:red;font-size:14px;margin:0 0 0;}
    button{margin-top:15px;padding:20px 30px;background-color:#B72E7B;color:#fff;cursor:pointer;-webkit-appearance:none;border-radius: 90px;font-weight:700;border:2px solid #fff;box-shadow: 0 3px 6px rgba(0,0,0,0.16);position:relative;
      svg{padding-left:10px;}
      &:hover{background-color:#3F8CBA;}
    }
  }
}

</style>
<template>
<div class="correct">
  <div class="content">
    <img src="@/images/correct-icon.svg" alt="correct" />
    <h3>Correct!</h3>
    <p>Congratulations you got it!  Please click the "Review" button below for additional information.</p>
  </div>
</div>
</template>
<style scoped lang="scss">
.correct{position: relative;height:100%;display: flex;align-items: center;justify-content: center;text-align: center;width:100%;
  .content{position: relative;z-index: 1;width:100%;
    h3{font-size: 80px;font-weight: 700;color: #0092BC;margin:20px 0;}
    p{font-size: 25px;font-weight: 700;color: #4D4D4D;margin:20px auto;display: block;width:600px;}
  }
}
</style>
<template>
  <div v-if="rating === 'pending'">
    <div class="performance-check">
      <div id="three" ref="rendererContainer"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import * as THREE from 'three';

export default {
  name: 'PerformanceCheck',
  emits: ['performance-data'],
  setup() {
    const rating = ref('pending');
    const store = useStore();
    const rendererContainer = ref(null);
    const fpsAvg = ref(0);
    const fps = [];
    function setupRenderer() {
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.shadowMap.enabled = true;

      // Make sure the rendererContainer is not null
      if (rendererContainer.value) {
        rendererContainer.value.appendChild(renderer.domElement);
      } else {
        console.error("Renderer container is not available.");
      }
      return renderer;
    }

    function checkCPUPerformance() {
      const cores = navigator.hardwareConcurrency;
      const cpuRating = cores >= 8 ? 4 : cores >= 4 ? 3 :  cores >= 2 ? 2 :  1;
      return { cores, cpuRating };
    }

    async function checkGPUPerformance(renderer) {
      return new Promise((resolve) => {

        const scene = new THREE.Scene();

        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0, 5, 10);
        camera.lookAt(0, 0, 0);

        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(5, 10, 5);
        light.castShadow = true;
        light.shadow.mapSize.width = 2048; // Increase for better shadow resolution
        light.shadow.mapSize.height = 2048;
        light.shadow.camera.near = 0.5;
        light.shadow.camera.far = 500;
        light.shadow.camera.left = -150;
        light.shadow.camera.right = 150;
        light.shadow.camera.top = 150;
        light.shadow.camera.bottom = -150;
        light.shadow.bias = -0.0001;
        scene.add(light);

        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(20, 20, 20);
        light2.castShadow = true;
        light2.shadow.mapSize.width = 2048; // Increase for better shadow resolution
        light2.shadow.mapSize.height = 2048;
        light2.shadow.camera.near = 0.5;
        light2.shadow.camera.far = 500;
        light2.shadow.camera.left = -150;
        light2.shadow.camera.right = 150;
        light2.shadow.camera.top = 150;
        light2.shadow.camera.bottom = -150;
        light2.shadow.bias = -0.0001;
        scene.add(light2);

        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshStandardMaterial({ color: 0xff0000 });
        const cube = new THREE.Mesh(geometry, material);
        cube.castShadow = true;
        cube.position.set(0, 2, 0);
        scene.add(cube);

        const planeGeometry = new THREE.PlaneGeometry(20, 20);
        const planeMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });
        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.receiveShadow = true;
        plane.rotation.x = -Math.PI / 2;
        scene.add(plane);

        measurePerformance(renderer, scene, camera, cube, resolve);
      });
    }

    function measurePerformance(renderer, scene, camera, cube, resolve) {
      let startTime = performance.now();
      let lastTime = startTime;
      let duration = 1000; // Measure for 5000 milliseconds

      function render() {
        let now = performance.now();
        let deltaTime = (now - lastTime) / 1000; // deltaTime in seconds
        lastTime = now;
        // Update the cube's rotation
        cube.rotation.x += deltaTime;
        cube.rotation.y += deltaTime;

        renderer.render(scene, camera);
        //create an array to populate with the fps values

        if (now - startTime < duration) {
          requestAnimationFrame(render);
          if(1/deltaTime !== Infinity){
            fps.push(1 / deltaTime);
          }
          fpsAvg.value = (fps.reduce((a, b) => a + b, 0) / fps.length).toFixed(2);
        } else {
          const gpuRating =  fpsAvg.value >= 60 ? 4 :  fpsAvg.value >= 40 ? 3 :   fpsAvg.value >= 20 ? 2 : 1;
          resolve({  fpsAvg, gpuRating });
        }
      }

      render();
    }

    function combineRatings(gpuRating, cpuRating) {
      const ratings = [1,2,3,4];
      const index = Math.min(ratings.indexOf(gpuRating), ratings.indexOf(cpuRating));
      return ratings[index];  // Return the lowest rating to ensure smooth performance
    }

    onMounted(async () => {
      const renderer = setupRenderer();
      if (renderer) {
        const { fpsAvg, gpuRating } = await checkGPUPerformance(renderer);
        const { cores, cpuRating } = checkCPUPerformance();
        const combinedRating = combineRatings(gpuRating, cpuRating);
        store.dispatch('handlePerformanceRating', {
          cpu: { cores, rating: cpuRating },
          gpu: { fpsAvg, rating: gpuRating },
          combinedRating
        });
      }
    });

    return {rating, rendererContainer, fpsAvg };
  }
}
</script>
<style lang="scss" scoped>
.performance-check{position: fixed;top:0;left: 0;width: 100%;height: 100%;height: 100dvh;height: 100vh;height: 100dvh;width:100vw;position:absolute;top:0;left:0;display: flex;justify-content: center;align-items: center;
  #three {position: fixed;top:0;left: 0;opacity:0;}
}

</style>

<template>
  <div class="timer" :class="{ active: timerStarted }">
    <svg class="progress-ring" width="140" height="140">
      <circle ref="progressCircle"  class="progress-ring__circle" stroke="#C6007E" stroke-width="10" fill="transparent" r="65" cx="70" cy="70" />
    </svg>
    <span>{{ timer }}</span>
  </div>
</template>


<script>
import { ref, onMounted, onUnmounted, computed, onUpdated } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'TimerComponent',
  emits: ['timeout'],
  setup(props, { emit }) {
    const store = useStore();
    //switch case to get the correct question if q
    const activityContent = computed(() => {
      if (store.getters.getCurrentSpaceType.includes('questions')){
        switch (store.getters.getCurrentSpaceType) {
          case 'questions.true-false':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getTrueFalse];
          case 'questions.multiple-choice':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getMultipleChoice];
          case 'questions.fill-in-blank':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getFillInBlank];
          case 'questions.unscramble':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getUnscramble];
          default:
            return '';
        }
      } else {
        return '';
      }
    });

    const timerStarted = ref(false);
    const timer = ref(activityContent.value.TimeLimitSeconds);
    let worker = null;
    const progressCircle = ref(null);

    const initializeWorker = (type,timeLeft) => {
      if (!timerStarted.value) timerStarted.value = true;
      if (worker) {
        worker.terminate();
      }
      worker = new Worker(new URL('@/timerWorker.js', import.meta.url));
      if (type === 'start') {
        worker.postMessage({ action: 'start', time: timeLeft, timeStamp: Date.now(), fullTime: activityContent.value.TimeLimitSeconds});
      }else{
        worker.postMessage({ action: 'resume', time: timeLeft, timeStamp: store.getters.getQuestion(activityContent.value.uniqueId).startTime, fullTime: activityContent.value.TimeLimitSeconds});
      }

      worker.onmessage = (e) => {
        if (e.data[0] === 'done') {
          updateTimer(e.data[1]);
          handleTimeout(e.data);
        }else if (e.data[0] === 'start'){
          store.dispatch('setStartTime', { uniqueId: activityContent.value.uniqueId, startTime: e.data[2] });
        }else {
          updateTimer(e.data[1]);
        }
      };
    };

    onUpdated(() => {
      updateProgressRing(timer.value);
    });

    const updateTimer = (time) => {
      timer.value = time;
      store.dispatch('setTimer', { uniqueId: activityContent.value.uniqueId, timer: time });
    };

    const updateProgressRing = (timeLeft) => {
      const circle = progressCircle.value;
      if (circle) {
        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;
        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = (timeLeft / activityContent.value.TimeLimitSeconds) * circumference;
      }
    };

    const handleTimeout = (data) => {
      store.dispatch('setEndTime', { uniqueId: activityContent.value.uniqueId, endTime: data[2] });
      store.dispatch('setTimeOut', { uniqueId: activityContent.value.uniqueId, timedOut: true });
      store.dispatch('setTimer', { uniqueId: activityContent.value.uniqueId, timer: data[1] });
      emit('timeout');
      worker.terminate();
      updateProgressRing(0);
    };


    onMounted(() => {
      const savedTimerData = store.getters.getQuestion(activityContent.value.uniqueId);
      if (savedTimerData) {
        if (savedTimerData.timer > 0) {
          updateProgressRing(savedTimerData.timer);
          timer.value = savedTimerData.timer;
          if (savedTimerData.startTime) {
            if(!savedTimerData.endTime){
              initializeWorker('resume',savedTimerData.timer);
            }
          } else {
            initializeWorker('start',activityContent.value.TimeLimitSeconds);
          }
        } else {
          if (!timerStarted.value) timerStarted.value = true;
          updateProgressRing(0);
          timer.value = 0;
        }
      }
    });

    const terminatenateWorker = () => {
      if (worker) worker.terminate();
    };

    onUnmounted(() => {
      if (worker) worker.terminate();
    });

    return { timer, timerStarted, progressCircle, terminatenateWorker};
  }
};
</script>



<style scoped>
.timer {width: 150px; height: 150px; position: absolute; left: 50%; top: 30px; transform: translate(-50%, -100%); background: #fff; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 50px; color: #4d4d4d; visibility: hidden;
  &.active {visibility: visible;}
  .progress-ring {position: absolute; top: 5px; left: 5px; width: 140px; height: 140px;background-image:url(@/images/timer-ring.svg);background-size: contain;}
  .progress-ring__circle {transition: 1s stroke-dashoffset; transform: rotate(-90deg); transform-origin: 50% 50%;}
}
</style>

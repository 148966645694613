<template>
  <div :class="[{ active: store.getters.getCardsShowing }]" class="ui-card-overlay"></div>
  <div :class="[{ active: store.getters.getCardsShowing }]" ref="cardWrapper" class="ui-card-wrapper">
    <div ref="cards" class="cards">
      <true-false-component @soundCorrect="soundCorrect" @soundIncorrect="soundIncorrect" :activityContent="activityContent" v-if="store.getters.getCurrentSpaceType === 'questions.true-false' && store.getters.getCardsShowing" @hideCards="hideCards" />
      <multiple-choice-component @soundCorrect="soundCorrect" @soundIncorrect="soundIncorrect" :activityContent="activityContent" v-if="store.getters.getCurrentSpaceType === 'questions.multiple-choice' && store.getters.getCardsShowing" @hideCards="hideCards" />
      <fill-in-blank-component @soundCorrect="soundCorrect" @soundIncorrect="soundIncorrect" :activityContent="activityContent" v-if="store.getters.getCurrentSpaceType === 'questions.fill-in-blank' && store.getters.getCardsShowing" @hideCards="hideCards" />
      <unscramble-component @soundCorrect="soundCorrect" @soundIncorrect="soundIncorrect" :activityContent="activityContent" v-if="store.getters.getCurrentSpaceType === 'questions.unscramble' && store.getters.getCardsShowing" @hideCards="hideCards" />
    </div>
  </div>
</template>

<script>
import {onMounted, onUnmounted, computed, ref} from 'vue';
import { useStore } from 'vuex';
import TrueFalseComponent from "@/components/game/CardComponents/TrueFalseComponent.vue";
import MultipleChoiceComponent from "@/components/game/CardComponents/MultipleChoiceComponent.vue";
import FillInBlankComponent from "@/components/game/CardComponents/FillInBlankComponent.vue";
import UnscrambleComponent from "@/components/game/CardComponents/UnscrambleComponent.vue";

export default {
  name: 'CardsComponent',
  components: {
    TrueFalseComponent,
    MultipleChoiceComponent,
    FillInBlankComponent,
    UnscrambleComponent
  },
  props: {
    soundUtility: Object
  },
  emits: ['hideCards'],
  setup(props,{emit}) {
    const store = useStore();
    const cardWrapper = ref(null);
    const cards = ref(null);
    const soundCorrect = () => {
      props.soundUtility.playSound('correct', 0.2, false);
    };
    const soundIncorrect = () => {
      props.soundUtility.playSound('incorrect', 0.2, false);
    };
    const activityContent = computed(() => {
      if (store.getters.getCurrentSpaceType.includes('questions')){
        switch (store.getters.getCurrentSpaceType) {
          case 'questions.true-false':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getTrueFalse];
          case 'questions.multiple-choice':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getMultipleChoice];
          case 'questions.fill-in-blank':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getFillInBlank];
          case 'questions.unscramble':
            return store.getters.getQuestions[store.getters.getCurrentSpaceType][store.getters.getUnscramble];
          default:
            return '';
        }
      } else {
        return '';
      }
    });

    const resizeCards = () => {
      if(cardWrapper.value === null || cards.value === null) return;
      const cardWrapperWidth = cardWrapper.value.offsetWidth;
      const cardWrapperHeight = cardWrapper.value.offsetHeight;
      let margin = 30;
      if (cardWrapperWidth < 750) {
        margin = cardWrapperWidth * 0.04;
      }
      if (cardWrapperWidth < 750 && cardWrapperHeight < 990) {
        let wrapperAspect = cardWrapperWidth / cardWrapperHeight;
        if (wrapperAspect >= 750 / 990) {
          let scaleY = cardWrapperHeight / 990;
          cards.value.style.transform = 'scale(' + scaleY + ')';
        } else {
          let scaleX = (cardWrapperWidth - margin) / 750;
          cards.value.style.transform = 'scale(' + scaleX + ')';
        }
      } else if (cardWrapperWidth < 750 && cardWrapperHeight >= 990) {
        let scaleX = (cardWrapperWidth - margin) / 750;
        cards.value.style.transform = 'scale(' + scaleX + ')';
      } else if (cardWrapperHeight < 990 && cardWrapperWidth >= 750) {
        let scaleY = cardWrapperHeight / 990;
        cards.value.style.transform = 'scale(' + scaleY + ')';
      } else {
        cards.value.style.transform = 'scale(1)';
      }
    };

    onMounted(() => {
      resizeCards();
      window.addEventListener('resize', resizeCards);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeCards);
    });

    const hideCards = (points) => {
      emit('hideCards', points);
    };

    return { store, hideCards, resizeCards, activityContent, cardWrapper, cards, soundCorrect, soundIncorrect};
  }
};
</script>

<style scoped lang="scss">
.ui-card-wrapper {opacity: 0; z-index: -3; position: absolute; left: 50%; width: 100%; transform: translateX(-50%); max-width: 750px; display: flex; align-items: center; justify-content: center; transition: opacity 1s ease-in-out 0s, z-index 0s ease-in-out 1s;
  .cards {width: 720px; height: 990px; transform-origin: 50% 50%; aspect-ratio: 990/720;}
  &.active {opacity: 1; z-index: 0; transition: opacity 1s ease-in-out 0s, z-index 0s ease-in-out 0s;}
}
.ui-card-overlay {opacity: 0; z-index: -3; position: absolute; top: 0; left: 0; width: 100%; background: rgba(0, 0, 0, 0.45); transition: opacity 1s ease-in-out 0s, z-index 0s ease-in-out 1s;
  &.active {opacity: 1; z-index: 0; transition: opacity 1s ease-in-out 0s, z-index 0s ease-in-out 0s;}
}
@media screen and (max-aspect-ratio: 1/1) {
  .ui-card-wrapper {top: min(16vw, 120px); height: calc(100% - min(34vw, 255px)); height: calc(100dvh - min(34vw, 255px));}
  .ui-card-overlay {height: calc(100% - min(16vw, 120px));height: calc(100vh - min(16vw, 120px));}
}
@media screen and (min-aspect-ratio: 1/1) {
  .ui-card-wrapper {top: min(16vh, 120px); height: calc(100vh - min(34vh, 255px));}
  .ui-card-overlay {height: calc(100vh - min(16vh, 120px));}
}
</style>

export default class SoundUtility {
    constructor(store) {
        this.store = store;
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.sounds = {};
        this.soundState = {};
        this.isMuted = store.getters.getMasterVolumeState;
    }

    toggleMute() {
        this.isMuted = !this.isMuted;
        this.store.dispatch('setMasterVolumeState', this.isMuted);

        for (const soundName in this.sounds) {
            if (Object.prototype.hasOwnProperty.call(this.soundState, soundName)) {
                const soundState = this.soundState[soundName];
                if (soundState) {
                    if (this.isMuted) {
                        soundState.gainNode.gain.value = 0;
                    } else {
                        soundState.gainNode.gain.value = this.soundState[soundName].volume;
                    }
                }
            }
        }
    }

    stopSound(soundName) {
        if (this.soundState[soundName]) {
            this.soundState[soundName].source.stop();
            this.soundState[soundName] = null;
        }
    }

    playSound(soundName, volume, loop) {
        if (this.sounds[soundName]) {
            const source = this.audioContext.createBufferSource();
            source.buffer = this.sounds[soundName];

            const gainNode = this.audioContext.createGain();
            if (this.isMuted) {
                gainNode.gain.value = 0;
            } else {
                gainNode.gain.value = volume;
            }

            source.connect(gainNode);
            gainNode.connect(this.audioContext.destination);

            source.loop = loop;
            source.start();

            this.soundState[soundName] = {
                loop: loop,
                volume: volume,
                source: source,
                gainNode: gainNode
            };
        } else {
            console.warn(`Sound ${soundName} not found`);
        }
    }

    async loadSound(soundName, filePath) {
        let ajax = new XMLHttpRequest();
        ajax.open("GET", filePath, true);
        ajax.responseType = "arraybuffer";

        ajax.onload = () => {
            this.audioContext.decodeAudioData(ajax.response, (audioBuffer) => {
                this.sounds[soundName] = audioBuffer;
            }, (error) => {
                console.error(`Error decoding sound ${soundName}: ${error}`);
            });
        };

        ajax.onerror = () => {
            console.error(`Error loading sound ${soundName}`);
        };

        ajax.send();
    }
}
